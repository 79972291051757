
const Hubspot = require('hubspot');
const hubspot = new Hubspot({
  apiKey: 'bd9345ca-dd58-46b4-be9f-418652a5198e',
  checkLimit: true
});
const formId = "48b1b01f-2825-4517-89a8-f0344ec5ca61";
const portalId = '6345754'
const formHash = 'a5fg4';
let form = undefined;

const sendToHubspot = (e) => {
  e.preventDefault();
  const submission = {};
  for (let i = form.elements.length - 1; i >= 0; i--) {
    const element = form.elements[i];
    if (element.type === 'email') {
      submission[element.name] = element.value;
    }
  }
  // Simple Honeypot
  if (submission['email']) {
    return;
  }

  hubspot.forms.submit(portalId, formId, {
    fields: [
      {
        name: 'email',
        value: submission[`email${formHash}`]
      }
    ],
    pageName: 'Benched Landing Page',
    pageURI: window.location.href
  }).then(results => {
    form.innerHTML = '<p>Thanks for submitting the form. We will keep you posted of Pigskin Fantasy U news, updates and more as we scrap the old playbook and set up for the 2020 season.</p>';
  }).catch(err => {
    console.error(err);
  })

}

document.addEventListener('DOMContentLoaded', () => {
  form = document.getElementById('j-form');

  if (!form) {
    return;
  }

  form.addEventListener('submit', sendToHubspot);
});